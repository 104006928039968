
let _instance = null;

class UI5Detector {
    constructor() {}

    detect(){
        let ui5ScriptTag = document.getElementById("sap-ui-bootstrap");
        if(ui5ScriptTag) return ui5ScriptTag;
        return false;
    }
}

_instance ??= new UI5Detector();

export default _instance;
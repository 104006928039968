import ErrorHandler from "../wc-utils/base/ErrorHandler";

export class KeyValidationService {

  ErrorHandler = new ErrorHandler();

  constructor() {}

  async validateAppCKey(appKey, configKey, containerID = undefined, skeletonLoader = undefined) {
    try {
      return await this.validateKeys(appKey, configKey);
    } catch (e) {
      //Todo: Outsource HTML to separate file(s)
      this.ErrorHandler.showCParamError(containerID, skeletonLoader);
      throw new Error("Invalid Key-Pair");
    }
  }

  async validateKeys(appKey, configKey) {
    try {
      return await jQuery.ajax({
        //dev
        url: `https://apps-digital-services.lenze.com/srv/akm/v1/validateAppKey(appKey='${appKey}',configKey='${
          configKey ?? ""
        }')`,
        type: "GET",
        success: (data, status) => {
          return {
            data: data.config,
            status: status,
          };
        },
        contentType: false,
      });
    } catch (e) {
      return e;
    }
  }
}

export default KeyValidationService;

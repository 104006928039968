import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { fetchI18nBundle, getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import InfohubcomponentWcTemplate from "./generated/templates/InfohubcomponentWcTemplate.lit.js";
import InfohubcomponentWcCss from "./generated/themes/InfohubcomponentWc.css.js";
import { PLEASE_WAIT } from "./generated/i18n/i18n-defaults.js";
import { ComponentHandler } from "./ComponentHandler.js";
import "@ui5/webcomponents-ie11/dist/features/IE11.js";
import "@ui5/webcomponents-ie11/dist/features/IE11WithWebComponentsPolyfill.js";

const metadata = {
  tag: "infohubcomponent-wc",
  properties: {
    throwGAEvents: {
      type: Boolean,
      defaultValue: false,
    },
    activeService: {
      type: String,
    },
    containerID: {
      type: String,
    },
    context: {
      type: String,
    },
    appKey: {
      type: String,
    },
    contextType: {
      type: String,
    },
    cParam: {
      type: String,
    },
    language: {
      type: String,
    },
    country: {
      type: String,
    },
    readUrlParams: {
      type: Boolean,
      defaultValue: false,
    },
    ieTextOnly: {
      type: Boolean,
      defaultValue: false,
    },
    // // String because booleans can't have "true" as default value in Webcomponents
    // showMatNrSearch: {
    //   type: String,
    //   defaultValue: "true",
    // },
    // showPcsSearch: {
    //   type: Boolean,
    //   defaultValue: false,
    // },
  },
  slots: {},
  events: {},
};

class InfohubcomponentWc extends UI5Element {
  constructor() {
    super();
    // this.waitForRenderedTag().then(() => {
    this.i18nBundle = getI18nBundle("infohubcomponentwc");
    // });
  }

  getPropertyValues(property, isBoolean = false) {
    if (isBoolean) {
      return this.attributes[property] ? (this.attributes[property].value === "true" ? true : false) : false;
    } else {
      return this.attributes[property]
        ? this.attributes[property].value || this[property]
        : this[property] || undefined;
    }
  }

  /*
   * Transforms comma-seperated strings
   */
  transformPropertyToArray(property) {
    if (property) {
      return property.replace(/ /g, "").split(",");
    } else {
      return property;
    }
  }

  setConstructorValues() {
    this.config = {
      containerID: this.getPropertyValues("containerId"),
      context: this.getPropertyValues("context") ?? "",
      appKey: this.getPropertyValues("appKey"),
      contextType: this.getPropertyValues("contextType"),
      cParam: this.getPropertyValues("cParam"),
      language: this.getPropertyValues("language"),
      country: this.getPropertyValues("country"),
      readUrlParams: this.getPropertyValues("readurlparams", true),
      ieTextOnly: this.getPropertyValues("ieTextOnly", true),
      activeService: this.getPropertyValues("activeService"),
      throwGAEvents: this.getPropertyValues("throwGAEvents", true),
      id: this.id,
      // showMatNrSearch: this.getPropertyValues("showMatNrSearch"),
      // showPcsSearch: this.getPropertyValues("showPcsSearch", true),
    };
  }

  get pleaseWaitText() {
    return this.i18nBundle.getText(PLEASE_WAIT);
  }

  static get metadata() {
    return metadata;
  }

  static get render() {
    return litRender;
  }

  get loadComponent() {
    this.setConstructorValues();
    this.ComponentHandler = new ComponentHandler(this.config);
    return this.ComponentHandler.loadComponent(this.config);
  }

  static get template() {
    return InfohubcomponentWcTemplate;
  }

  static get styles() {
    return InfohubcomponentWcCss;
  }

  static async onDefine() {
    await fetchI18nBundle("infohubcomponentwc");
  }
}

InfohubcomponentWc.define();

export default InfohubcomponentWc;

import { SkeletonLoaderDataProvider } from "./util/SkeletonLoaderDataProvider.js";
import { UrlExtractor, Base64Handler, GAEventHandler } from "../../../wc-utils/exports";
import { Initializer, BaseComponentHandler } from "../../../wc-ui5-core/exports";
import KeyValidationService from "../../../wc-services/KeyValidationService";

export class ComponentHandler extends BaseComponentHandler {
  UrlExtractor = new UrlExtractor(new URL(window.location.href).search);
  Base64Handler = new Base64Handler();
  KeyValidationService = new KeyValidationService();

  constructor(config) {
    super(config, {
      skeletonLoaderHTML: SkeletonLoaderDataProvider.skeletonloaderHtml,
      skeletonLoaderCSS: SkeletonLoaderDataProvider.skeletonloaderCSS,
    });
  }

  initializeComponent() {
    sap.ui.require(
      [
        "jquery.sap.global",
        "com/lenze/ui5/lib/productinfo/enum/ComponentType",
        "com/lenze/ui5/lib/productinfo/enum/ContextType",
        "com/lenze/ui5/lib/productinfo/core/ComponentFactory",
      ],
      async (jquery, ComponentType, ContextType, ComponentFactory) => {
        let componentConfig = await this.getComponentDataConfig();
        let { appKey, configKey, cParam, containerID } = componentConfig;

        //Set search component defaults
        const defaultValShowMatNrSearch = true;
        const defaultValShowPcsSearch = false;

        //Wrap
        let keyValidationResponse = await this.KeyValidationService.validateAppCKey(appKey, configKey);
        let responseData = keyValidationResponse.config;

        componentConfig = { ...componentConfig, ...responseData };
        componentConfig.showMatNrSearch =
          componentConfig.additionalParams?.materialSearch?.showMatNrSearch ?? defaultValShowMatNrSearch;
        componentConfig.showPcsSearch =
          componentConfig.additionalParams?.materialSearch?.showPcsSearch ?? defaultValShowPcsSearch;

        //Wrap
        let contextPathValue = componentConfig.readUrlParams ? this.UrlExtractor.checkForContextPath() : null;

        //Wrap
        if (componentConfig.cParam) {
          componentConfig.context = String(contextPathValue ?? componentConfig.context);
        }

        componentConfig.contextType = componentConfig.contextType || ContextType.MaterialNumber;
        let componentStartupParameters = { cParam: componentConfig.cParam };

        // Create a Component, based on parameters handed over
        let componentData = ComponentFactory.getInstance().buildComponent(
          ComponentType.Hub,
          componentConfig,
          this.readUrlParams ?? true,
          componentStartupParameters
        );

        let component = componentData.component,
          params = componentData.params;

        const { serviceList, activeService, disableService, hiddenService, showPcsSearch, showMatNrSearch } =
          componentConfig;

        // TODO: Use startUpParams instead
        component.setDisabledServiceKeys(disableService);
        component.setHiddenServiceKeys(hiddenService);
        component.setVisibleServiceKeys(serviceList);
        component.setSelectedServiceKey(activeService);
        component.setEnableBarcodeScanner(params.extendService?.includes?.("scanSerial") || false);
        component.setEnableShare(params.extendService?.includes?.("shareUri") || false);
        component.setEnablePrlcy(params.extendService?.includes?.("showPrlcy") || false);
        component.setEnableDocFinderV2(params.extendService?.includes?.("docfinderv2") || false);
        component.setShowPcsSearch(showPcsSearch);
        component.setShowMatNrSearch(showMatNrSearch);

        /*** Throw Google Analytics events ***/
        if (componentConfig.throwGAEvents) {
          // GAEventHandler.registerComponentEvents(component);
        }

        component.attachReady(() => {
          this.SkeletonLoader.hideSkeletonLoader();
          // window.jQuery.sap = jquery.sap;
          // window.jQuery.prototype.control = jquery.prototype.control;
        });

        component.getRootControl().addEventDelegate({
          onAfterRendering: () => {
            setTimeout(() => {
              window.jQuery = jquery;
              window.$ = jquery;
            }, 1000);
          },
        });

        new sap.ui.core.ComponentContainer({
          component: component,
        }).placeAt(containerID);

        this.handleDOMLifecycle(containerID, component);

      }
    );
  }
}

export default ComponentHandler;

const baseUrls = {
  productInfo: 'https://apps-digital-services.lenze.com/pri/app',
  productLifecycle: "https://apps-digital-services.lenze.com/plc/app",
  changeOver: "https://apps-digital-services.lenze.com/cho/app",
};

const ui5Config = {
  UI5VERSION: "1.96.30",
  THEME_NAME: "sap_belize",
  UI5CORE_VARIANT: "sap-ui-core", // or -nojQuery
  FRAMEWORK: "sapui5",
  RESOURCEROOTS:
    '{"com.lenze.ui5.productLifecycleDisplay" : "' +
    baseUrls.productLifecycle +
    '", "com.lenze.ui5.lib.productinfo.component.hub": "' +
    baseUrls.productInfo +
    '/component/hub", "com.lenze.ui5.lib.productinfo": "' +
    baseUrls.productInfo +
    '", "com.lenze.ui5.kontaktform": "' +
    baseUrls.productInfo +
    '/resources/kontaktform/", "com.lenze.ui5.rma": "' +
    baseUrls.productInfo +
    '/resources/rma", "com.lenze.ui5.qrgenerator" : "' +
    baseUrls.productInfo +
    '/resources/qrcode", "com.lenze.ui5.changeover": "' +
    baseUrls.changeOver +
    '", "com.lenze.ui5.docfinder" : "' +
    baseUrls.productInfo +
    '/resources/docfinder_v2"}',
};

const baseConfig = {
  CONSIDERABLE_URLPARAMS: [
    "context",
    "contextType",
    "c",
    "appKey",
    "activeService",
    "language",
    "country",
    "contextType",
    "documentId",
    "documentLanguage"
  ],
};

export { ui5Config, baseConfig };

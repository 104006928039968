export class ErrorHandler {
    containerId = null;
    skeletonLoader = null;
  
    constructor(containerId, skeletonLoader) {
      if (!ErrorHandler.instance) {
        this.containerId = containerId;
        this.skeletonLoader = skeletonLoader;
        ErrorHandler.instance = this;
      }
      return ErrorHandler.instance;
    }
  
     /**
     * Displays an error message if the cParam is invalid
     * @public
     * @param {*} containerID 
     * @param {*} skeletonLoader 
     */
     showCParamError () {
      debugger;
        jQuery(`#${this.containerId}`).html(
          "<!DOCTYPE html><style>.invalidKeyErrorContainer{width: 100%; background-color: #f5f5f5; display:flex; }.invalidKeyErrorContainer h1{width:100%; margin:auto; font-size: 20px; margin-top:50px; margin-bottom:50px ;text-align:center; color: rgb(51, 51, 51); font-family: 'Open Sans',Helvetica,Arial,sans-serif; font-weight:100; }</style><html><div class='invalidKeyErrorContainer'> <h1>Wrong access licence for service component</h1></div></html>"
        );
        this.skeletonLoader.hideSkeletonLoader();
      }

  }
  
  export default ErrorHandler;
  
import OutdatedBrowserHandler from "../wc-utils/base/OutdatedBrowserHandler.js";
import UI5Detector from "../wc-utils/ui5/UI5Detector.js";
import Initializer from "./Initializer.js";
import DataTypeTransformer from "../wc-utils/base/DataTypeTransformer.js";
import XMLHelper from "../wc-utils/base/XmlHelper.js";
import { SkeletonLoader } from "../wc-utils/base/Skeletonloader.js";
import { baseConfig } from "./config.js";
import { UrlExtractor } from "../wc-utils/exports.js";
import { countryDefaults } from "../wc-utils/base/CountryDefaults.js";
import ErrorHandler from "../wc-utils/base/ErrorHandler.js";

export class BaseComponentHandler {
  constructor(defaultConfiguration, skeletonloaderConfig) {
    let domHtmlTag = window.document.getElementsByTagName("html")[0].attributes["lang"];
    let language = defaultConfiguration.language || (domHtmlTag ? domHtmlTag.value.split("-")?.[0] : "en");
    let country = defaultConfiguration.country || (domHtmlTag ? domHtmlTag.value.split("-")?.[1] : "us");
    this.config = { ...defaultConfiguration, ...{ language, country } };
    this.OutdatedBrowserHandler = new OutdatedBrowserHandler(this.config);
    this.SkeletonLoader = new SkeletonLoader({
      ...{ appAreaId: this.config.containerID, wcContainerId: this.config.id },
      ...skeletonloaderConfig,
    });
    this.ErrorHandler = new ErrorHandler(this.config.containerID, this.SkeletonLoader);
  }

  /**
   * Sets the component configuration data
   */
  async getComponentDataConfig() {
    let language = this.config.language || "",
      country = this.config.country || countryDefaults[language.toLowerCase()],
      { contextType, context } = this.config;

    if (contextType === "jsonParamListEncoded") {
      const transformedStructure = this.#transformJsonToXml(context, contextType);
      this.config.contextType = transformedStructure.contextType;
      this.config.context = transformedStructure.context;
    }

    // Todo: Set paramList depending on the incoming xml type
    let componentConfiguration = { ...this.config, language, country };
    componentConfiguration = componentConfiguration.readUrlParams
      ? this.prioritizeURLParameters(componentConfiguration)
      : componentConfiguration;
    componentConfiguration =
      componentConfiguration.cParam || componentConfiguration.c
        ? this.prioritizeCParameter(componentConfiguration)
        : componentConfiguration;

    // If contextType equals to 'xmlParamListEncoded', replace the language properties with the configured ones
    if (componentConfiguration.contextType === "xmlParamListEncoded") {
      const validationResponse = await this.validateContext();
      componentConfiguration.xmlType = validationResponse.xmlType;
      componentConfiguration.context = XMLHelper.replaceXMLParams({ ...componentConfiguration });
    }

    return componentConfiguration;
  }

  /**
   *
   * @param {*} componentConfiguration
   * @returns
   */

  prioritizeURLParameters(componentConfiguration) {
    let urlParameterValues = {},
      searchParams = baseConfig.CONSIDERABLE_URLPARAMS;
    for (let param of searchParams) {
      urlParameterValues[param] = this.UrlExtractor.checkForURLParameters(param) ?? componentConfiguration[param] ?? "";
    }
    return { ...componentConfiguration, ...urlParameterValues };
  }

  /**
   * Prioritizes the C-Parameter over native integration-parameters and url-parameters
   * @param {*} componentDataConfig
   * @returns {componentDataConfig} The componentDataConfig with the prioritized c-parameter
   * @throws {Error} If the c-parameter is not a valid base64 string (In the form of html)
   */
  prioritizeCParameter(componentDataConfig) {
    let base64EncodedProperties = componentDataConfig.c || componentDataConfig.cParam;
    let base64DecodedProperties;
    try {
      base64DecodedProperties = this.Base64Handler.decodeBase64(base64EncodedProperties);
    } catch {
      this.ErrorHandler.showCParamError();
      throw new Error("Malformed license key");
    }
    return { ...componentDataConfig, ...base64DecodedProperties };
  }

  /**
   * Transforms a json structure to a xml structure
   * @param {*} context
   * @param {*} contextType
   * @returns {context, contextType} The new context and contextType after transformation
   */
  #transformJsonToXml(context, contextType) {
    if (contextType === "jsonParamListEncoded") {
      const xmlContext = DataTypeTransformer.json2xml(JSON.parse(atob(context)), "");
      context = btoa(xmlContext);
      contextType = "xmlParamListEncoded";
    }
    return { context, contextType };
  }

  /**
   * Set UI5 language manually if parameter exists
   */
  setUi5Language() {
    let urlExtractor = new UrlExtractor(),
      language = urlExtractor.checkForURLParameters("language") || this.config.language || "en";
    return language ? sap.ui.getCore().getConfiguration().setLanguage(language) : null;
  }

  /**
   * create the HTML containerelement for the webcomponent, since we cannot use the shadowdom
   */
  createContainer(id, isFullWidth) {
    let infoWebComponent = document.querySelector(`#${id}`),
      containerDiv = document.createElement("div");
    containerDiv.id = this.config.containerID;
    if (isFullWidth) containerDiv.classList.add("has-full-width");
    infoWebComponent.parentNode.insertBefore(containerDiv, infoWebComponent);
  }

  async validateContext() {
    const isValid = await (
      await fetch("https://apps-digital-services.lenze.com/srv/integration/validateXml", {
        body: JSON.stringify({ encodedXmlDoc: this.config.context }),
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
    ).json();

    if (!isValid.success) throw new Error("Context validation failed");

    return isValid;
  }

  handleDOMLifecycle(containerId, component) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const containers = document.querySelectorAll(`#${containerId} > .sapUiComponentContainer`); 
        if (containers.length > 0) {
          containers.forEach((container, index) => {
            if (index + 1 != containers.length) {
              this.SkeletonLoader.remove(); 
              container.remove();
            }
          })
        }
         observer.disconnect(); 
      });
    });

    // Start observing the container element
    observer.observe(document.getElementById(containerId), { childList: true });
    
  }

  deleteExistingWebComponentWithId(containerId) {
    const componentContainer = document.getElementById(containerId);
    if (componentContainer) {
      componentContainer.remove();
    }
  }

  /**
   * Load and provide the complete webcomponent
   */
  loadComponent() {
    const isFullWidth = this.config.isFullWidth ?? true;
    const componentId = this.config.id;
    const containerId = this.config.containerID;

    this.deleteExistingWebComponentWithId(containerId);

    this.createContainer(componentId, isFullWidth);

    const loadAndConfigureComponent = () => {
      this.initializeComponent();
      this.setUi5Language();
      // Todo: only do when contextType is xml and implement errorhandling
    };

    this.SkeletonLoader.loadSkeletonLoader();

    if (this.OutdatedBrowserHandler.checkForOutdatedBrowser()) {
      /**
       * Check for already existing ui5-script-tag.
       * Note: We cannot go async here, since this function will be called by IE-Users
       */
      const script = UI5Detector.detect();
      if (script) {
        new Promise((resolve, reject) => {
          /**
           * Wait until already existing ui5-script-tag has finished loading
           * Todo: Do we need this when checking the window level property ui5CoreLoaded?
           */
          script.addEventListener("load", function () {
            resolve();
          });

          //Wait until ui5-core has finished loading
          setInterval(() => {
            if (window?.lenze?.sap?.ui5CoreLoaded) {
              resolve();
            }
          }, 250);
        }).then(() => {
          loadAndConfigureComponent();
        });
      } else {
        Initializer.startUi5Bootstrapping().then(() => {
          window.lenze = {
            sap: { ui5CoreLoaded: true },
          };
          loadAndConfigureComponent();
        });
      }
    } else {
      this.OutdatedBrowserHandler.setBlockerMarkup();
    }
  }
}

export default BaseComponentHandler;

import { ui5Config } from './Config';

let _instance = null;

class Initializer {

    constructor() {
    }

    startUi5Bootstrapping() {
        const { FRAMEWORK, UI5VERSION, UI5CORE_VARIANT, THEME_NAME, RESOURCEROOTS } = ui5Config;
        return new Promise(function (resolve, reject) {
            let head = document.getElementsByTagName("head")[0];
            let script = document.createElement("script");
            script.addEventListener("load", function () {
                resolve();
            });
            script.src =
                `https://sapui5.hana.ondemand.com/${UI5VERSION}/resources/sap-ui-core.js`;
            script.id = "sap-ui-bootstrap";
            script.async = false;
            script.defer = false;
            script.setAttribute("data-sap-ui-compatVersion", "edge");
            script.setAttribute("data-sap-ui-async", "true");
            script.setAttribute("data-sap-ui-resourceroots", RESOURCEROOTS);
            script.setAttribute("data-sap-ui-theme", THEME_NAME);
            script.setAttribute("data-sap-ui-noConflict", "true");
            script.setAttribute("data-sap-ui-frameOptions", "trusted");
            head.appendChild(script);

        });
    }
}

_instance ??= new Initializer();
export default _instance;
export default class XMLHelper {

    constructor() { }

    /**
      * Replaces language and country attributes in the xml context
      * @param {*} componentConfiguration 
      * @returns {String} Base64 encoded, replaced context
      */
    static replaceXMLParams(componentConfiguration) {
        const parser = new DOMParser(), serializer = new XMLSerializer();
        let { context, language, country } = componentConfiguration;
        let xmlContext = parser.parseFromString(atob(context), "application/xml"),
            replacedLanguage = language,
            replacedCountry = country;

        xmlContext.querySelector('part[mappingType="SAP"]')?.setAttribute("nLang", `${replacedLanguage}-${replacedCountry}`);
        xmlContext.querySelector('guiLanguage')?.setAttribute("value", `${replacedLanguage}-${replacedCountry}`)
        if (xmlContext.querySelector('Country')) xmlContext.querySelector('Country').innerHTML = replacedCountry;
        return btoa(serializer.serializeToString(xmlContext));
    }
}
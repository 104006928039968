export class UrlExtractor {
  constructor(urlSearchParams) {
    this.urlSearchParams = urlSearchParams;
  }

  checkForURLParameters(parameterName) {
    let url = new URL(window.location.href),
      searchParams = new URLSearchParams(url.search),
      urlParam = searchParams.has(parameterName)
        ? searchParams.get(parameterName)
        : undefined;

    return urlParam;
  }

  checkForContextPath() {
    let urllocation = window.location.href

    const supportOrPIRegex = /https:\/\/(www\.)?(staging.lenze|lenze)\.com\/(support|product-information)\/(?:[a-zA-Z0-9_-]+\/)?([a-zA-Z0-9_-]+)(\?.*)?/;
    // const generalSiteRegex = /(.*)digital-services.lenze.com\/(.*)/;
    let regexContextValue;
    
    if (
      urllocation.match(supportOrPIRegex)
    ) {
      regexContextValue = urllocation.match(supportOrPIRegex)?.[4];
      if(regexContextValue.includes("?")) regexContextValue = regexContextValue.split("?")[0];
    } 
    // else if (urllocation.match(generalSiteRegex)) {
    //   regexContextValue = urllocation.match(generalSiteRegex)?.[2];
    // } 
    // else {
    //   regex = /(.*)\/pages\/(.*)[?]?(.*)/;
    //   regexContextValue = urllocation.match(regex)?.[2];
    // }
    return regexContextValue || null;
  }
}

export default UrlExtractor;

export class SkeletonLoaderDataProvider {
  constructor() {}

  static get skeletonloaderHtml() {
    return `<div id="placeholder">
    <div class="search placeholder-inner has-full-width">
        <div class="placeholder-content">
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
        </div>
    </div>
    <div class="nav placeholder-inner has-full-width">

        <div class="placeholder-content">
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
            <div class="placeholder-content_item"></div>
        </div>

    </div>
</div>`;
  }

  static get skeletonloaderCSS() {
    return `.page {
  width: 650px;
  margin: 10% auto 0;
  padding: 20px;
  background: #e5e5e5;
}
.page-title {
  margin-top: 5px;
  margin-bottom: 10px;
}
.placeholder-inner {
  background: #f5f5f5;
  padding: 25px 0;
}
.placeholder-inner.search {
  margin-bottom: 20px;
}
.placeholder-inner.nav {
  background: #eeeeee;
  margin-bottom: 20px;
}
.placeholder-content {
  height: 155px;
  overflow: hidden;
  background: #000;
  position: relative;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8;
  background: linear-gradient(to right, #ddd 2%, #ccc 18%, #ddd 33%);
  background-size: 1300px;
}
.nav .placeholder-content {
  height: 275px;
}
.placeholder-content_item {
  width: 100%;
  height: 20px;
  position: absolute;
  background: #f5f5f5;
  z-index: 2;
}
.placeholder-content_item:after,
.placeholder-content_item:before {
  width: inherit;
  height: inherit;
  content: "";
  position: absolute;
}
.search .placeholder-content_item:nth-child(1) {
  top: 0;
  left: 0;
  height: 5px;
}
.search .placeholder-content_item:nth-child(2) {
  top: 5px;
  left: 0;
  width: 23%;
  height: 200px;
}
.search .placeholder-content_item:nth-child(3) {
  top: 5px;
  right: 0;
  width: 23%;
  height: 200px;
}
.search .placeholder-content_item:nth-child(4) {
  top: 5px;
  left: 0;
  width: 38%;
  height: 35px;
}
.search .placeholder-content_item:nth-child(5) {
  top: 5px;
  right: 0;
  width: 38%;
  height: 35px;
}
.search .placeholder-content_item:nth-child(6) {
  top: 35px;
  width: 100%;
  left: 0px;
  height: 30px;
}
.search .placeholder-content_item:nth-child(7) {
  top: 110px;
  width: 100%;
  left: 0px;
  height: 10px;
}
.search .placeholder-content_item:nth-child(8) {
  top: 145px;
  width: 100%;
  left: 0px;
  height: 40px;
}
.search .placeholder-content_item:nth-child(9) {
  top: 120px;
  width: 100%;
  left: 46%;
  height: 30px;
}
.nav .placeholder-content_item {
  background: #eeeeee;
}
.nav .placeholder-content_item:nth-child(1) {
  top: 0;
  left: 0;
  height: 5px;
}
.nav .placeholder-content_item:nth-child(2) {
  top: 5px;
  left: 0;
  width: 11%;
  height: 400px;
}
.nav .placeholder-content_item:nth-child(3) {
  top: 5px;
  right: 0;
  width: 16%;
  height: 400px;
}
.nav .placeholder-content_item:nth-child(4) {
  top: 5px;
  left: 0;
  width: 38%;
  height: 42px;
}
.nav .placeholder-content_item:nth-child(5) {
  top: 5px;
  right: 0;
  width: 38%;
  height: 42px;
}
.nav .placeholder-content_item:nth-child(6) {
  top: 45px;
  width: 100%;
  left: 0px;
  height: 30px;
}
.nav .placeholder-content_item:nth-child(7) {
  top: 74px;
  width: 20px;
  left: 420px;
  height: 210px;
}
.nav .placeholder-content_item:nth-child(8) {
  top: 160px;
  width: 100%;
  left: 0px;
  height: 20px;
}
.nav .placeholder-content_item:nth-child(9) {
  top: 260px;
  width: 100%;
  left: 0;
  height: 30px;
}
.nav .placeholder-content_item:nth-child(10) {
  top: 74px;
  width: 20px;
  left: calc(420px + 267px);
  height: 210px;
}
.nav .placeholder-content_item:nth-child(11) {
  top: 74px;
  width: 20px;
  left: calc(420px + 267px + 267px);
  height: 210px;
}
.nav .placeholder-content_item:nth-child(12) {
  top: 179px;
  width: 476px;
  right: 0;
  height: 130px;
}
@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}`;
  }
}

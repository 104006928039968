
/**
 * @public
 * Mapping of fallback countries used, when there is no country specified in the HTML-Tag of the site
 */
 export let countryDefaults = {
    en : "US",
    de : "DE",
    cs : "CZ",
    es : "ES",
    fr : "FR",
    hu : "HU",
    it : "IT",
    pl : "PL",
    pt : "PT",
    ru : "RU",
    zh : "CN"
}